import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 17; i++) {
    if (i === 9) continue;
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/6-oct-2/pic${i}.jpg`;
    imgArray.push(imgUrl);
  }

  for (let i = 1; i <= 14; i++) {
    if (i === 14 || i === 10) continue;
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/6-oct-2/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function GandhiJayanti2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Gandhi Jayanti Celebration</h1>

        <p>
          <strong>
            “A man is but a product of his thoughts. What he thinks he becomes.”
            – Mahatma Gandhi
          </strong>
          <br />
          <br />
          Chinmaya Vidyalaya, New Delhi, celebrated the birth anniversary of the
          Father of the Nation - Mohandas Karamchand Gandhi by conducting a
          myriad of activities. The students of Senior School initiated a
          cleanliness and plantation drive to spread the message of clean and
          green environment.Additionally, they enjoyed a soulful bhajan and it
          was followed by the screening of a movie- The Mahatma Returns.
          <br />
          <br />
          The students of classes I and II presented a special assembly which
          showcased a soulful bhajan 'Raghupati Raghav Raja Ram', a captivating
          dance performance and a solo song ‘Dedi Hume Azadi' on harmonium. The
          assembly helped the students learn the values of honesty, non-violence
          and love for humanity.
          <br />
          <br />
          The Pre-Primary Department conducted a Cleanliness Drive. The students
          watched a video about Gandhiji's life and principles. The little
          learners enjoyed freehand drawings on Gandhiji's glasses, spinning
          wheel and the tricolor.Through these activities, the students learnt
          the values of cleanliness, non-violence, and patriotism.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
